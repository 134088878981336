<template>
  <div>
    <b-card no-body>
      <div>
        <!-- Table Fitler -->
        <div class="mb-2">
          <!-- Search Condition -->
          <b-row>
            <b-col sm="3">
              <b-form-group
                class="font-small-3 mt-lg-1"
                label="사이트"
                label-for="message-select"
                label-align-sm="2"
                content-cols-sm="6"
              >
                <b-form-select
                  v-if="authSite"
                  id="messages-site"
                  ref="messages-site"
                  v-model="selectedSite"
                  :options="authSite"
                  class="font-small-3"
                  size="sm"
                  style="color:rgb(229,166,48); "
                >
                  <template
                    v-if="false"
                    #first
                  >
                    <b-form-select-option :value="null">
                      전체
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col
              offset-sm="3"
              sm="6"
            >
              <b-form-group
                class="font-small-3"
                label-align-sm="3"
                label="수신일자"
                label-for="message-period"
              >
                <b-input-group
                  id="message-period"
                  class="calendar-size font-small-3"
                >
                  <b-form-datepicker
                    v-model="periodFrom"
                    class=""
                    boundary="window"
                    locale="ko"
                    placeholder="시작일"
                    aria-controls="example-input"
                    size="sm"
                    :max="periodTo"
                  />
                  <div class="pl-1 pr-1">
                    ~
                  </div>
                  <b-form-datepicker
                    v-model="periodTo"
                    class=""
                    boundary="window"
                    locale="ko"
                    placeholder="종료일"
                    aria-controls="example-input"
                    size="sm"
                    :min="periodFrom"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="3">
              <b-form-group
                class="font-small-3"
                label="show"
                label-for="message-select"
                label-align-sm="2"
                content-cols-sm="6"
              >
                <b-form-select
                  v-model="perPage"
                  :options="perPageOptions"
                  size="sm"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
            <b-col
              offset-sm="3"
              sm="6"
            >
              <b-form-group class="mb-0 pr-1">
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="searchFilter"
                    type="search"
                    placeholder="검색: 상위/추천, 닉네임/이름, 아이디, 전화번호 ..."
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!searchFilter"
                      @click="searchFilter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- table content -->
        <div>
          <b-table
            ref="messagesReceiveHistoryTable"
            responsive
            show-empty
            hover
            small
            empty-text="No matching records found"
            style="text-align: center; font-size: small"
            :per-page="perPage"
            :items="fetchMessagesAdminFromUser"
            :fields="messagesHistoyReceiveFields"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :current-page="currentPage"
            @row-clicked="fncToggleRowDetails"
            @filtered="onFiltered"
          >
            <template #cell(index)="data">
              {{ totalRows - (perPage * (currentPage - 1) + data.index) }}
            </template>
            <template #cell(senderId)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 5rem; text-decoration: underline; text-underline-offset: 5px;"
                @click.stop="userClicked(data)"
              >
                {{ data.item.senderId }}
              </div>
            </template>
            <template #cell(reads)="data">
              <div style="align-items: center; min-width: 9rem;">
                <span
                  style="text-align: center; flex-grow: 1; max-width: 4rem;"
                >
                  {{ fncReadsAndTotalCount(data.item.receivers) }}
                </span>
                <b-badge variant="primary">
                  <feather-icon
                    type="button"
                    icon="UsersIcon"
                    size="12"
                    class="text-body"
                    style="color:white !important;"
                    @click.stop="fncModalMessagesDetail(data.item.receivers)"
                  />
                </b-badge>
              </div>
            </template>
            <template #cell(title)="data">
              <div
                style="min-width: 11rem;"
                size="sm"
              >
                {{ data.item.title }}
              </div>
            </template>
            <template #cell(createdAt)="data">
              <div
                style="min-width: 11rem;"
                size="sm"
              >
                {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm:ss') }}
              </div>
            </template>
            <template #cell(readAt)="data">
              <div
                style="min-width: 11rem;"
                size="sm"
              >
                {{ data.item.readAt | formatDate('YYYY-MM-DD HH:mm:ss') }}
              </div>
            </template>
            <template #row-details="row">
              <div v-if="row.item._showDetails">
                <b-card
                  class="p-0 text-left"
                  style="background-color: #212026;"
                >
                  <b> 내용 : </b> {{ row.item.message }}
                </b-card>
              </div>
            </template>
          </b-table>
        </div>
        <!-- table footer -->
        <div>
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-left justify-content-left justify-content-sm-start pl-4"
              style="margin-bottom: 0.5rem;"
            >
              <span
                class="font-small-2"
              >{{ pageFrom }}~{{ pageTo }} / 총 {{ pageOf }}
              </span>
            </b-col>
            <b-col
              cols="12"
              sm="12"
              class="d-flex align-items-center justify-content-center"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import * as moment from 'moment-timezone'
import { createNamespacedHelpers } from 'vuex'

import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormSelectOption,
  BFormDatepicker,
  BTable,
  BPagination,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { FETCH_MESSAGES_ADMIN_FROM_USER } from '@/store/messages/action'
import { UPDATE_MESSAGES_READ } from '@/store/messages/mutation'

import fncShowSwal from './_vueSweetalert2'
import router from "@/router";

const messagesStore = createNamespacedHelpers('messagesStore')

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormSelectOption,
    BFormDatepicker,
    BTable,
    BPagination,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      userIp: localStorage.getItem('ip'),
      selectedSite: localStorage.getItem('authSiteSelected'),
      pageRole: this.$route.path.split('/').pop(),
      msgType: 'rcv', // 수신/발신
      periodFrom: moment(new Date()).startOf('month').format(fmt1),
      periodTo: moment(new Date()).endOf('month').format(fmt1),
      // TableOption
      searchFilter: '',
      perPageOptions: [25, 50, 100],
      currentPage: 1, // 현재 페이지
      perPage: 25, // 페이지 당 표시할 항목 수
      pageFrom: 0,
      pageTo: 0,
      pageOf: 0,
      totalRows: 0, // 총 메시지 수 (서버에서 얻을 수도 있습니다)
      sortBy: 'createdAt',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      // TableContent
      messagesHistoyReceiveFields: [
        { key: 'index', label: 'No.' },
        { key: 'site', label: '사이트' },
        { key: 'title', label: '제목', tdClass: 'text-over text-left' },
        { key: 'senderId', label: '발송자' },
        { key: 'senderIp', label: '발송 IP' },
        { key: 'createdAt', label: '수신 일시' },
        { key: 'readAt', label: '열람 시간' },
      ],

      // Modal
      messagesReceivers: [],
    }
  },
  computed: {
    ...messagesStore.mapGetters({
      fetchMessagesAdminFromUser: 'fetchMessagesAdminFromUser',
      updateMessagesRead: 'updateMessagesRead',
    }),
    // 검색필터된 Items
    filteredItems() {
      let filteredData = this.fetchMessagesAdminFromUser
      if (this.selectedSite) {
        filteredData = filteredData.filter(item => item.site.includes(this.selectedSite))
      }
      if (this.searchFilter) {
        filteredData = filteredData.filter(item => Object.values(item).some(
          val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase()),
        ))
      }
      return filteredData
    },
  },
  watch: {
    selectedSite: {
      handler(event) {
        localStorage.setItem('authSiteSelected', event || localStorage.getItem('authSiteSelected'))
        this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },
    periodFrom() {
      this.fetchData()
    },
    periodTo() {
      this.fetchData()
    },
  },
  created() {
    this.$socket.on('requestAlarmsMessageAdminRefresh', async data => {
      console.log(data)
      await this.fetchData()
    })
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    // methods에는 mapAction, mapMutate를 선언한다
    ...messagesStore.mapActions({
      $fetchMessagesAdminFromUser: FETCH_MESSAGES_ADMIN_FROM_USER,
      $updateMessagesRead: UPDATE_MESSAGES_READ,
    }),
    async fetchData() {
      let param
      if (this.pageRole === 'godfather') {
        param = {
          site: 'GAEGGUL',
          userid: 'perdonis',
          role: 'godfather',
          type: this.msgType,
          periodFrom: this.periodFrom,
          periodTo: this.periodTo,
        }
      } else {
        param = {
          site: this.selectedSite,
          userid: this.userData.userid,
          role: this.userData.role,
          type: this.msgType,
          periodFrom: this.periodFrom,
          periodTo: this.periodTo,
        }
      }
      await this.$fetchMessagesAdminFromUser(param)
      this.totalRows = this.fetchMessagesAdminFromUser.length
    },
    async fncToggleRowDetails(item) {
      this.$set(item, '_showDetails', !item._showDetails)
      // 읽음처리
      // console.log('item ::', item)
      if (item._showDetails && item.isRead !== 'Y') {
        await this.$updateMessagesRead({
          idx: item.idx,
          site: this.selectedSite,
          receiverIp: this.userIp,
          receiverId: item.receiverId, //socket 공유를 위한 수신자 정보 송부
        })
        item.readAt = new Date().toISOString() // 현재 시간을 읽음 시간으로 설정 (실시간 반영)
        item.isRead = 'Y'
      }
    },
    fncModalMessagesDetail(item) {
      this.messagesReceivers = item // 모달에 넘겨줄 데이터 설정
      this.$refs.messagesHistorySendReceiversInfoModal.showModal() // 모달 열기
    },
    userClicked(data) {
      localStorage.setItem('userView', JSON.stringify({ userid: data.item.senderId, site: data.item.site }))
      const url = router.resolve({ name: 'apps-user-view' }).href
      // window.open(url, '_blank')
      const popupName = `userViewPopup_${new Date().getTime()}`
      const popupOptions = 'width=1800,height=1250,scrollbars=yes,resizable=yes' // 원하는 옵션 설정
      window.open(url, popupName, popupOptions)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style></style>
